import { EMPTY, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Theme } from '@api/models/enums';
import { Preferences } from '@api/models/preferences.entity';
import { UserConstants } from '@api/models/user.constants';
import { User } from '@api/models/user.entity';
import { ProfileEditFormsTranslations } from '@bussiness/i18n/user.i18n';
import { UsersFacade } from '@bussiness/store/features/users/users-facade';
import {
	PlcTypedFormControl,
	PlcValidationErrorType,
} from '@components/models/foms.models';
import { PlcTranslatable } from '@core/interfaces/translatable.interface';
import {
	PlcUserAsyncValidators,
	PlcUserValidators,
} from '@core/validators/user.validators';

export interface ProfileEditFormValues {
	name: string;
	email: string;
	preferences: Preferences;
}

@Injectable()
export class ProfileEditForms
	implements PlcTranslatable<ProfileEditFormsTranslations>
{
	public i18n: ProfileEditFormsTranslations;

	public readonly nameControl: PlcTypedFormControl<string>;
	public readonly emailControl: PlcTypedFormControl<string>;
	public readonly volumeControl: PlcTypedFormControl<number>;
	public readonly themeControl: PlcTypedFormControl<Theme>;

	public get volume(): number {
		return this.volumeControl.value ?? 0;
	}

	public get valueIsEqualToStore$(): Observable<boolean> {
		return this.usersFacade.current$.pipe(
			filter((user: User) => !!user),
			map((user: User) => {
				const { name, email, preferences } = user;
				const storedData = {
					name: name,
					email: email,
					preferences: {
						hideWizard: preferences
							? preferences.hideWizard
							: false,
						volume: preferences.volume,
						theme: preferences.theme,
					},
				};

				return (
					JSON.stringify(storedData) ===
					JSON.stringify(this._form.value)
				);
			}),
		);
	}

	public get isValid(): boolean {
		return this._form.valid;
	}

	public get value(): ProfileEditFormValues {
		return this._form.value;
	}

	private _form: UntypedFormGroup;

	constructor(private usersFacade: UsersFacade) {
		this.setTranslations();

		this.nameControl = new PlcTypedFormControl('', {
			validators: [
				Validators.required,
				Validators.minLength(UserConstants.nameMinLen),
				Validators.maxLength(UserConstants.nameMaxLen),
			],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.nameRequired,
					interpolatedParams: { name: this.i18n.name },
				},
				{
					error: PlcValidationErrorType.minLen,
					message: this.i18n.nameMinlen,
					interpolatedParams: {
						name: this.i18n.name,
						min: UserConstants.nameMinLen,
					},
				},
				{
					error: PlcValidationErrorType.maxLen,
					message: this.i18n.nameMaxlen,
					interpolatedParams: {
						name: this.i18n.name,
						max: UserConstants.nameMaxLen,
					},
				},
				{
					error: PlcValidationErrorType.nameExists,
					message: this.i18n.nameExists,
					interpolatedParams: { name: this.i18n.name },
				},
			],
		});

		this.emailControl = new PlcTypedFormControl('', {
			validators: [Validators.required, PlcUserValidators.email],
			validationErrors: [
				{
					error: PlcValidationErrorType.required,
					message: this.i18n.emailRequired,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailNotValid,
					message: this.i18n.emailValid,
					interpolatedParams: { name: this.i18n.email },
				},
				{
					error: PlcValidationErrorType.emailExists,
					message: this.i18n.emailExists,
					interpolatedParams: { name: this.i18n.email },
				},
			],
		});

		this.volumeControl = new PlcTypedFormControl('');
		this.themeControl = new PlcTypedFormControl('');

		this._form = new UntypedFormGroup({
			name: this.nameControl,
			email: this.emailControl,
			preferences: new UntypedFormGroup({
				volume: this.volumeControl,
				theme: this.themeControl,
			}),
		});

		this.usersFacade.current$
			.pipe(
				tap((user: User) => {
					const { name, email, preferences } = user;
					this._form.reset({
						name,
						email,
						preferences,
					});

					this.nameControl.setAsyncValidators([
						PlcUserAsyncValidators.isNewOrEqual(name, 'name'),
					]);

					this.emailControl.setAsyncValidators([
						PlcUserAsyncValidators.isNewOrEqual(email, 'email'),
					]);
				}),
			)
			.subscribe(() => EMPTY);
	}

	setTranslations(): void {
		this.i18n = {
			name: 'forms.fields.name-prefix',
			email: 'forms.fields.email-prefix',
			nameRequired: 'forms.validations.required',
			nameMinlen: 'forms.validations.minlength',
			nameMaxlen: 'forms.validations.maxlength',
			nameExists: 'forms.validations.already-exists',
			emailRequired: 'forms.validations.required',
			emailValid: 'forms.validations.valid-format',
			emailExists: 'forms.validations.already-exists',
		};
	}
}
